<template>
  <div class="carbon-sink-index">
    <div class="card-info">
      <router-link tag="div" to="/carbon-sink/what" class="flex note"><van-icon name="question-o" />解释</router-link>
      <div class="top">
        <h2>{{ userInfo.name || '--' }}</h2>
        <p>{{ userInfo.villageName }} {{ userInfo.buildingNo }}栋 {{ userInfo.unitNo }}单元 {{ userInfo.houseNumber }}室</p>
      </div>
      <div class="flex bottom">
        <div class="info-col">
          <p>绿豆</p>
          <h3>{{ points.p0 }}</h3>
        </div>
        <div class="info-col">
          <p>蓝豆</p>
          <h3>{{ points.p1 }}</h3>
        </div>
        <div class="info-col">
          <p>碳汇(吨)</p>
          <h3>{{ points.p2 | fixed2 }}</h3>
        </div>
      </div>
    </div>
    <div class="log-list">
      <van-cell size="large" title="明细记录">
        <template #default>
          <van-button round type="primary" size="small" @click="showTimePicker = true">选择时间</van-button>
        </template>
      </van-cell>
      <van-cell v-for="(item, index) in list" :key="index" :class="item.status === 1 ? '' : item.type == 0 ? 'plus' : 'minus'" size="large" :title="formatTitle(item)" :label="$helper.formatTime(item.time)" :value="item.status === 1 ? '待审核' : (item.type == 0 ? '+' : '-') + item.number" />
    </div>
    <van-popup v-model="showTimePicker" position="bottom">
      <van-datetime-picker v-model="filterDate" type="date" title="选择日期" :min-date="minDate" :max-date="maxDate"  @cancel="showTimePicker = false" @confirm="getCreditDetail(), showTimePicker = false"/>
    </van-popup>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showTimePicker: false,
      filterDate: new Date(new Date().toLocaleDateString()),
      minDate: new Date(2019, 1, 1),
      maxDate: new Date(),
      userInfo: {},
      list: [],
      pointType: 1, // 0绿豆 1蓝豆 2碳汇
      points: {
        'p0': '-',
        'p1': '-',
        'p2': '-'
      }
    }
  },
  filters: {
    fixed2(val) {
      return (parseInt(val/1000)/1000)
    }
  },
  created() {
    this.getUserInfo()
  },
  methods: {
    getUserInfo() {
      this.$http.get(`/village/household/${this.$store.state.token}`).then(res => {
        if (res.code === 0) {
          this.userInfo = res.data
          this.getUserCredit(0)
          this.getUserCredit(1)
          this.getUserCredit(2)
          this.getCreditDetail()
        }
      })
    },
    getUserCredit(index) {
      this.$http.get('/village/userpoint/getUserCredit', {
        params: {
          rfid: this.userInfo.rfid,
          pointType: index
        }
      }).then(res => {
        if (res.code === 0) {
          this.points['p' + index] = res.data.currentPoint
        }
      })
    },
    getCreditDetail() {
      this.$http.get('/village/userpoint/getCreditDetail', {
        params: {
          rfid: this.userInfo.rfid,
          page: 1,
          limit: 50,
          queryDate: this.filterDate.valueOf() + 24 * 60 * 60 * 1000 - 1
        }
      }).then(res => {
        if (res.code === 0 && res.data) {
          this.list = res.data.list
        }
      })
    },
    formatTitle(item) {
      if (item.type == 0) {
        return item.reason
      } else {
        switch (item.reason) {
          case '0':
            return '提现'
          case '1':
            return '购物'
          case '2':
            return '分类错误'
          case '3':
            return '恶意投放'
          default:
            return '其他'
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.carbon-sink-index {
  background: #F1F1F1;
  padding: .4rem;
  .card-info {
    position: relative;
    height: 4.71rem;
    margin-bottom: .3rem;
    color: #FFF;
    background: url('../../assets/bg/carbon-sink-index-card.svg') no-repeat scroll center / cover border-box;
    .note {
      position: absolute;
      right: 0;
      top: .7rem;
      height: .65rem;
      border-top-left-radius: .325rem;
      border-bottom-left-radius: .325rem;
      background-color: rgba(255, 255, 255, .4);
      line-height: .65rem;
      padding: 0 .2rem;
      .van-icon-question-o {
        font-size: .37rem;
        margin-right: .03rem;
      }
    }
    .top {
      height: 2.71rem;
      border-bottom: .01rem solid rgba(255, 255, 255, .4);
      margin: 0 .71rem;
      padding-top: .71rem;
      h2 {
        font-size: .53rem;
      }
      p {
        font-size: .37rem;
      }
    }
    .bottom {
      height: 1.9rem;
      .info-col {
        border-right: .01rem solid rgba(255, 255, 255, .4);
        text-align: center;
        height: 1rem;
        flex: 1;
        text-align: center;
      }
      .info-col:last-child {
        border-right: none;
      }
    }
  }
  .log-list {
    background-color: #FFF;
    border-radius: .4rem;
    overflow: hidden;
    .log-title {
      height: 1.52rem;
      border-bottom: .01rem solid #ebedf0;
    }
    .plus {
      .van-cell__value {
        color: #40AE36;
      }
    }
    .minus {
      .van-cell__value {
        color: #FF7400;
      }
    }
  }
}
</style>